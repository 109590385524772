import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
//import { getAnalytics } from "firebase/analytics";



// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAJnszQCoYAhYZ4h7sSdbZ2lE0PaatkgTs",
    authDomain: "red2o-40af0.firebaseapp.com",
    projectId: "red2o-40af0",
    storageBucket: "red2o-40af0.appspot.com",
    messagingSenderId: "851471680760",
    appId: "1:851471680760:web:1e69a695b5dc18b9c3e70d",
    measurementId: "G-7JFJ8FVFS2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
//const analytics = getAnalytics(app);
export const db = getFirestore(app);

export const createUserWithRole = async (user, role = 'customer') => {
    await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        role: role,
        profileComplete: false
    });
};

export const getUserRole = async (uid) => {
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data().role;
    }
    return null;
};

export const checkProfileCompletion = async (uid) => {
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data().profileComplete || false;
    }
    return false;
};

export const updateUserProfile = async (uid, profileData) => {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, {
        ...profileData,
        profileComplete: true
    });
};