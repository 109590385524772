import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from 'services/firebase';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [profileComplete, setProfileComplete] = useState(false);

    useEffect(() => {
        const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const userDocRef = doc(db, 'users', currentUser.uid);
                const unsubscribeSnapshot = onSnapshot(userDocRef, (docSnap) => {
                    if (docSnap.exists()) {
                        setProfileComplete(docSnap.data().profileComplete || false);
                    } else {
                        setProfileComplete(false);
                    }
                    setLoading(false);
                });

                // Unsubscribe from Firestore snapshot when unmounting
                return () => unsubscribeSnapshot();
            } else {
                setUser(null);
                setProfileComplete(false);
                setLoading(false);
            }
        });

        // Unsubscribe from Auth listener when component unmounts
        return () => unsubscribeAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ user, loading, profileComplete }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
