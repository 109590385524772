import React, { useState, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Menu, X, User } from 'lucide-react';
import { useAuth } from 'contexts/AuthContext';
import { auth } from 'services/firebase';

const Header = () => {
    const { user } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const isAuthenticated = !!user;

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleSignOut = () => {
        auth.signOut();
        navigate('/main');
        setIsOpen(false);
    };

    const scrollToSection = useCallback((sectionId) => {
        setIsOpen(false);
        if (location.pathname !== '/main') {
            navigate('/main');
            // Wait for navigation to complete before scrolling
            setTimeout(() => {
                const element = document.getElementById(sectionId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        } else {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.pathname, navigate]);

    const menuItems = [
        { id: "about", label: "About" },
        { id: "services", label: "Services" },
        { id: "contact", label: "Contact" },
        { id: "top", label: "Home" },
    ];

    const authItems = isAuthenticated
        ? [
            { to: "/welcome", label: "My Account", icon: <User size={18} /> },
            { onClick: handleSignOut, label: "Sign Out" }
        ]
        : [
            { to: "/signup", label: "Sign Up" },
            { to: "/signin", label: "Sign In" },
        ];

    const NavItem = ({ item }) => {
        if (item.id) {
            return (
                <button onClick={() => scrollToSection(item.id)} className="hover:underline">
                    {item.label}
                </button>
            );
        } else if (item.to) {
            return (
                <Link to={item.to} className="hover:underline flex items-center" onClick={() => setIsOpen(false)}>
                    {item.icon && <span className="mr-2">{item.icon}</span>}
                    {item.label}
                </Link>
            );
        } else {
            return (
                <button onClick={item.onClick} className="hover:underline">
                    {item.label}
                </button>
            );
        }
    };

    return (
        <header className="bg-blue-600 text-white sticky top-0 z-20">
            <div className="container mx-auto px-4">
                <div className="flex justify-between items-center py-4">
                    <button onClick={() => scrollToSection('top')} className="text-2xl font-bold">Real Estate Dream to Own</button>
                    <nav className="hidden md:flex space-x-6">
                        {menuItems.concat(authItems).map((item, index) => (
                            <NavItem key={item.id || item.to || index} item={item} />
                        ))}
                    </nav>
                    <div className="md:hidden">
                        <button onClick={toggleMenu} className="focus:outline-none">
                            {isOpen ? <X size={24} /> : <Menu size={24} />}
                        </button>
                    </div>
                </div>
                {isOpen && (
                    <nav className="md:hidden pb-4">
                        {menuItems.concat(authItems).map((item, index) => (
                            <div key={item.id || item.to || index} className="block py-2 hover:bg-blue-700">
                                <NavItem item={item} />
                            </div>
                        ))}
                    </nav>
                )}
            </div>
        </header>
    );
};

export default Header;