// src/components/tables/ViewBuyers.js
import React, { useEffect, useState } from 'react';
import { db } from 'services/firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import LoadingScreen from 'components/common/LoadingScreen';
import { FaUser, FaPhone, FaEnvelope, FaMapMarkerAlt, FaHome, FaBed, FaBath, FaDog, FaListUl } from 'react-icons/fa';
import { FiTarget } from "react-icons/fi";

const ViewBuyers = () => {
    const [loading, setLoading] = useState(true);
    const [buyers, setBuyers] = useState([]);
    const [editingBuyer, setEditingBuyer] = useState(null);
    const [editFormData, setEditFormData] = useState({});

    useEffect(() => {
        const fetchBuyers = async () => {
            setLoading(true);
            try {
                const querySnapshot = await getDocs(collection(db, 'users'));
                const buyersList = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.role === 'customer' && data.interest === 'buying') {
                        buyersList.push({ id: doc.id, ...data });
                    }
                });
                setBuyers(buyersList);
            } catch (error) {
                console.error('Error fetching buyers:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBuyers();
    }, []);

    const handleEditClick = (buyer) => {
        setEditingBuyer(buyer);
        setEditFormData({ ...buyer });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            const buyerRef = doc(db, 'users', editingBuyer.id);
            await updateDoc(buyerRef, editFormData);
            setEditingBuyer(null);
            setBuyers((prev) => prev.map((buyer) => (buyer.id === editingBuyer.id ? editFormData : buyer)));
        } catch (error) {
            console.error('Error updating buyer:', error);
        }
    };

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div>
            <h2 className="text-2xl font-bold mb-6">Buyers</h2>
            <table className="min-w-full bg-white mb-8">
                <thead>
                <tr>
                    <th className="py-2 px-4 border-b"><FaUser /></th>
                    <th className="py-2 px-4 border-b"><FaPhone /></th>
                    <th className="py-2 px-4 border-b"><FaEnvelope /></th>
                    <th className="py-2 px-4 border-b"><FaListUl /></th>
                    <th className="py-2 px-4 border-b"><FaListUl /></th>
                    <th className="py-2 px-4 border-b"><FiTarget />  $/Mo.</th>
                    <th className="py-2 px-4 border-b"><FaMapMarkerAlt /></th>
                    <th className="py-2 px-4 border-b"><FaHome /></th>
                    <th className="py-2 px-4 border-b"><FaBed /></th>
                    <th className="py-2 px-4 border-b"><FaBath /></th>
                    <th className="py-2 px-4 border-b"><FaDog /></th>
                    <th className="py-2 px-4 border-b">⚙️</th>
                </tr>
                </thead>
                <tbody>
                {buyers.map((buyer) => (
                    <tr key={buyer.id}>
                        <td className="py-2 px-4 border-b">{buyer.name}</td>
                        <td className="py-2 px-4 border-b">{buyer.phone}</td>
                        <td className="py-2 px-4 border-b">{buyer.email}</td>
                        <td className="py-2 px-4 border-b">{buyer.contactMethod}</td>
                        <td className="py-2 px-4 border-b">{buyer.contactTime}</td>
                        <td className="py-2 px-4 border-b">{buyer.monthlyBudget || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{buyer.targetedNeighborhoods || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{buyer.targetedHousingTypes?.join(', ') || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{buyer.bedroomsMin || 'N/A'} - {buyer.bedroomsMax || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{buyer.bathroomsMin || 'N/A'} - {buyer.bathroomsMax || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{buyer.petsRequired || 'None'}</td>
                        <td className="py-2 px-4 border-b">
                            <button className="text-blue-600 hover:underline" onClick={() => handleEditClick(buyer)}>Edit</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {editingBuyer && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-start justify-center overflow-y-auto pt-16">
                    <div className="bg-white p-8 rounded-md shadow-lg w-full max-w-lg mx-4 my-8">
                        <h2 className="text-2xl font-bold mb-4">Edit Buyer - {editingBuyer.name}</h2>
                        <form onSubmit={handleEditSubmit}>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="name">Name:</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={editFormData.name || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="phone">Phone:</label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    value={editFormData.phone || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={editFormData.email || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="contactMethod">Contact Method:</label>
                                <input
                                    type="text"
                                    id="contactMethod"
                                    name="contactMethod"
                                    value={editFormData.contactMethod || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="contactTime">Contact Time:</label>
                                <input
                                    type="text"
                                    id="contactTime"
                                    name="contactTime"
                                    value={editFormData.contactTime || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="monthlyBudget">Targeted Monthly Lease:</label>
                                <input
                                    type="number"
                                    id="monthlyBudget"
                                    name="monthlyBudget"
                                    value={editFormData.monthlyBudget || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="targetedNeighborhoods">Targeted Neighborhood(s):</label>
                                <input
                                    type="text"
                                    id="targetedNeighborhoods"
                                    name="targetedNeighborhoods"
                                    value={editFormData.targetedNeighborhoods || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="targetedHousingTypes">Targeted Housing Types:</label>
                                <select
                                    id="targetedHousingTypes"
                                    name="targetedHousingTypes"
                                    value={editFormData.targetedHousingTypes || []}
                                    onChange={(e) => {
                                        const options = Array.from(e.target.selectedOptions, (option) => option.value);
                                        setEditFormData((prev) => ({
                                            ...prev,
                                            targetedHousingTypes: options,
                                        }));
                                    }}
                                    multiple
                                    className="w-full border rounded px-2 py-1"
                                >
                                    <option value="Single Family Home">Single Family Home</option>
                                    <option value="Multi-Family Home">Multi-Family Home</option>
                                    <option value="Condominium">Condominium</option>
                                    <option value="Tiny Home">Tiny Home</option>
                                    <option value="Land">Land</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Targeted Number of Bedrooms:</label>
                                <div className="flex items-center space-x-4">
                                    <div>
                                        <label htmlFor="bedroomsMin" className="block text-gray-700">Min:</label>
                                        <input
                                            type="number"
                                            id="bedroomsMin"
                                            name="bedroomsMin"
                                            value={editFormData.bedroomsMin || ''}
                                            onChange={handleEditChange}
                                            className="border rounded px-2 py-1"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="bedroomsMax" className="block text-gray-700">Max:</label>
                                        <input
                                            type="number"
                                            id="bedroomsMax"
                                            name="bedroomsMax"
                                            value={editFormData.bedroomsMax || ''}
                                            onChange={handleEditChange}
                                            className="border rounded px-2 py-1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Targeted Number of Bathrooms:</label>
                                <div className="flex items-center space-x-4">
                                    <div>
                                        <label htmlFor="bathroomsMin" className="block text-gray-700">Min:</label>
                                        <input
                                            type="number"
                                            id="bathroomsMin"
                                            name="bathroomsMin"
                                            value={editFormData.bathroomsMin || ''}
                                            onChange={handleEditChange}
                                            className="border rounded px-2 py-1"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="bathroomsMax" className="block text-gray-700">Max:</label>
                                        <input
                                            type="number"
                                            id="bathroomsMax"
                                            name="bathroomsMax"
                                            value={editFormData.bathroomsMax || ''}
                                            onChange={handleEditChange}
                                            className="border rounded px-2 py-1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="petsRequired">Pets Required:</label>
                                <select
                                    id="petsRequired"
                                    name="petsRequired"
                                    value={editFormData.petsRequired || 'None'}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                >
                                    <option value="None">None</option>
                                    <option value="Cats">Cats</option>
                                    <option value="Dogs">Dogs</option>
                                    <option value="Cats and Dogs">Cats and Dogs</option>
                                </select>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    className="mr-4 px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
                                    onClick={() => setEditingBuyer(null)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewBuyers;