import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const LandingPage = () => {
    const navigate = useNavigate();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const imagesRef = useRef([
        '/images/hawaii-808613_960_720.jpg',
        '/images/hawaii-bigisle.jpg',
        '/images/hawaii-waimanalo.jpg'
    ]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagesRef.current.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const handleExplore = () => {
        navigate('/main');
    };

    return (
        <div className="relative h-screen overflow-hidden">
            {imagesRef.current.map((src, index) => (
                <div
                    key={index}
                    className={`absolute inset-0 transition-opacity duration-1000 ${
                        index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                    <img src={src} alt={`Hawaiian scenery ${index + 1}`} className="object-cover w-full h-full" />
                </div>
            ))}

            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white p-4">
                <h1 className="text-5xl font-bold mb-6 text-center">Welcome to Real Estate Dream to Own</h1>
                <p className="text-xl mb-8 text-center max-w-2xl">
                    Your journey to home ownership starts here. Discover how we're making dreams come true for buyers and sellers alike.
                </p>
                <button
                    onClick={handleExplore}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full text-lg transition duration-300 ease-in-out transform hover:scale-105 flex items-center"
                >
                    Explore Our Services
                    <ArrowRight className="ml-2" />
                </button>
            </div>
        </div>
    );
};

export default LandingPage;