// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import LoadingScreen from 'components/common/LoadingScreen';

const ProtectedRoute = ({ children, requireProfileComplete = true }) => {
    const { user, loading, profileComplete } = useAuth();

    if (loading) {
        return <LoadingScreen />;
    }

    if (!user) {
        return <Navigate to="/signin" replace />;
    }

    if (requireProfileComplete && !profileComplete) {
        return <Navigate to="/profile-completion" replace />;
    }

    if (!requireProfileComplete && profileComplete) {
        return <Navigate to="/welcome" replace />;
    }

    return children;
};

export default ProtectedRoute;
