import React, { useState, useEffect } from 'react';
import { auth, db } from 'services/firebase';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import Header from 'components/common/Header';

const WelcomePage = () => {
    const [userData, setUserData] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        contactMethod: 'email',
        contactTime: '',
        interest: 'buying',
    });
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setIsAuthenticated(!!user);
            if (user) {
                const userRef = doc(db, 'users', user.uid);
                getDoc(userRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        setUserData(docSnap.data());
                        setFormSubmitted(true);
                    }
                });
            }
        });
        return () => unsubscribe();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const user = auth.currentUser;
        if (user) {
            const userRef = doc(db, 'users', user.uid);
            await setDoc(userRef, formData);
            setUserData(formData);
            setFormSubmitted(true);
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-50">
            <Header isAuthenticated={isAuthenticated} />
            <main className="flex-grow flex flex-col items-center justify-center p-4">
                {!formSubmitted ? (
                    <form onSubmit={handleSubmit} className="bg-white p-8 rounded-md shadow-lg w-full max-w-md">
                        <h2 className="text-2xl font-bold mb-6 text-center">Complete Your Profile</h2>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-semibold mb-2" htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-md"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-semibold mb-2" htmlFor="phone">Phone
                                Number</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-md"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-semibold mb-2" htmlFor="contactMethod">Preferred
                                Contact Method</label>
                            <select
                                id="contactMethod"
                                name="contactMethod"
                                value={formData.contactMethod}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-md"
                            >
                                <option value="email">Email</option>
                                <option value="phone">Phone</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-semibold mb-2" htmlFor="contactTime">Preferred
                                Contact Time</label>
                            <input
                                type="text"
                                id="contactTime"
                                name="contactTime"
                                value={formData.contactTime}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-md"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-semibold mb-2">Interested in</label>
                            <div className="flex space-x-4">
                                <label>
                                    <input
                                        type="radio"
                                        name="interest"
                                        value="buying"
                                        checked={formData.interest === 'buying'}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Buying</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="interest"
                                        value="selling"
                                        checked={formData.interest === 'selling'}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Selling</span>
                                </label>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white font-bold py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
                        >
                            Submit
                        </button>
                    </form>
                ) : (
                    <div className="text-center">
                        <h2 className="text-2xl font-semibold mb-4">Nice to meet you, {userData.name}!</h2>
                        <p>
                            Someone from our team will reach out to you
                            by {userData.contactMethod} at {userData.contactMethod === 'email' ? userData.email : userData.phone} during {userData.contactTime} to
                            learn more about your real estate goals!
                        </p>
                    </div>
                )}
            </main>
            <footer className="bg-gray-800 text-white p-6 sticky bottom-0 z-10">
                <div className="container mx-auto text-center">
                    <p>&copy; 2024 Real Estate Dream to Own. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default WelcomePage;