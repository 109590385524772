import React from 'react';
import Header from 'components/common/Header';
import ViewBuyers from 'components/customers/ViewBuyers';
import ViewSellers from 'components/customers/ViewSellers';

const CustomersPage = () => {
    return (
        <div className="flex flex-col min-h-screen bg-gray-50">
            <Header isAuthenticated={true} />
            <main className="flex-grow p-4">
                <div className="container mx-auto">
                    <ViewBuyers />
                    <ViewSellers />
                </div>
            </main>
        </div>
    );
};

export default CustomersPage;