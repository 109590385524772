import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { db, checkProfileCompletion, updateUserProfile } from 'services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from 'contexts/AuthContext'; // Use AuthContext
import Header from 'components/common/Header';
import LoadingScreen from 'components/common/LoadingScreen';

const ProfileCompletion = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        contactMethod: 'email',
        contactTime: '',
        interest: 'buying',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [redirectPath, setRedirectPath] = useState(null);
    const { user, setLoading: setAuthLoading } = useAuth(); // Access user and setLoading function from context

    useEffect(() => {
        const checkUserStatus = async () => {
            if (user) {
                const isProfileComplete = await checkProfileCompletion(user.uid);
                if (isProfileComplete) {
                    setRedirectPath('/welcome');
                } else {
                    const userDoc = await getDoc(doc(db, 'users', user.uid));
                    if (userDoc.exists()) {
                        setFormData((prevState) => ({
                            ...prevState,
                            ...userDoc.data(),
                        }));
                    }
                    setLoading(false);
                }
            } else {
                setRedirectPath('/signin');
            }
        };

        checkUserStatus();
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (user) {
                await updateUserProfile(user.uid, formData);
                setAuthLoading(true); // Start loading to force context update
                setRedirectPath('/welcome');
            }
        } catch (error) {
            setError(error.message);
        }
    };

    if (redirectPath) {
        return <Navigate to={redirectPath} />;
    }

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div className="flex flex-col min-h-screen bg-gray-50">
            <Header isAuthenticated={true} />
            <main className="flex-grow flex flex-col items-center justify-center p-4">
                <form
                    onSubmit={handleSubmit}
                    className="bg-white p-8 rounded-md shadow-lg w-full max-w-md"
                >
                    <h2 className="text-2xl font-bold mb-6 text-center">Complete Your Profile</h2>
                    {error && <p className="text-red-600 mb-4 text-center">{error}</p>}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="phone">Phone Number</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="contactMethod">Preferred Contact Method</label>
                        <select
                            id="contactMethod"
                            name="contactMethod"
                            value={formData.contactMethod}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        >
                            <option value="email">Email</option>
                            <option value="phone">Phone</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="contactTime">Preferred Contact Time</label>
                        <input
                            type="text"
                            id="contactTime"
                            name="contactTime"
                            value={formData.contactTime}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Interested in</label>
                        <div className="flex space-x-4">
                            <label>
                                <input
                                    type="radio"
                                    name="interest"
                                    value="buying"
                                    checked={formData.interest === 'buying'}
                                    onChange={handleChange}
                                />
                                <span className="ml-2">Buying</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="interest"
                                    value="selling"
                                    checked={formData.interest === 'selling'}
                                    onChange={handleChange}
                                />
                                <span className="ml-2">Selling</span>
                            </label>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white font-bold py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
                    >
                        Submit
                    </button>
                </form>
            </main>
        </div>
    );
};

export default ProfileCompletion;
