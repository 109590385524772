import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DollarSign, Home, Clock, ThumbsUp, Phone, Mail } from 'lucide-react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'services/firebase';
import Header from 'components/common/Header';

const MainContent = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
        });
        return () => unsubscribe();
    }, []);

    return (
        <div className="flex flex-col min-h-screen bg-gray-50">
            <Header isAuthenticated={isAuthenticated} />
            <main className="flex-grow">
                <section className="bg-gradient-to-r from-blue-500 to-blue-700 text-white py-20">
                    <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
                        <div className="md:w-1/2 mb-10 md:mb-0">
                            <h1 className="text-4xl md:text-5xl font-bold mb-4">Revolutionizing Home Ownership</h1>
                            <p className="text-xl mb-6">Watch our founder explain how we're changing the real estate game.</p>
                        </div>
                        <div className="md:w-1/2">
                            <div className="aspect-w-16 aspect-h-9 bg-gray-200 rounded-lg shadow-lg">
                                {/* Replace 'VIDEO_ID' with your actual YouTube video ID */}
                                <iframe
                                    title="Real Estate Dream to Own Introduction"
                                    src="https://www.youtube.com/embed/VIDEO_ID"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    className="rounded-lg"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4 text-center">
                        <h2 className="text-3xl font-bold mb-8">Ready to Make Your Real Estate Dreams a Reality?</h2>
                        <Link
                            to="/signup"
                            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center"
                        >
                            Sign Up Now
                        </Link>
                        <p className="mt-4 text-gray-600">Sign up to see available homes or to sell your home faster</p>
                    </div>
                </section>

                <section id="about" className="py-16 bg-gray-100">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-8 text-center">About Us</h2>
                        <p className="text-xl text-center max-w-3xl mx-auto">At Real Estate Dream to Own, we're committed to making home ownership a reality for everyone. Our innovative approach bridges the gap between buyers and sellers, creating win-win situations.</p>
                    </div>
                </section>

                <section id="services" className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-12 text-center">Our Services</h2>
                        <div className="grid md:grid-cols-2 gap-12">
                            <div className="bg-blue-50 p-8 rounded-lg shadow-lg">
                                <h3 className="text-2xl font-semibold mb-4">For Buyers</h3>
                                <ul className="space-y-4">
                                    <li className="flex items-center"><DollarSign className="mr-3 text-green-500"/> <span className="text-lg">No interest payments</span></li>
                                    <li className="flex items-center"><Home className="mr-3 text-green-500"/> <span className="text-lg">Affordable monthly payments</span></li>
                                    <li className="flex items-center"><Clock className="mr-3 text-green-500"/> <span className="text-lg">Own your home in 7-15 years</span></li>
                                </ul>
                            </div>
                            <div className="bg-blue-50 p-8 rounded-lg shadow-lg">
                                <h3 className="text-2xl font-semibold mb-4">For Sellers</h3>
                                <ul className="space-y-4">
                                    <li className="flex items-center"><DollarSign className="mr-3 text-blue-500"/> <span className="text-lg">Earn extra monthly income</span></li>
                                    <li className="flex items-center"><Clock className="mr-3 text-blue-500"/> <span className="text-lg">Less time waiting for a buyer</span></li>
                                    <li className="flex items-center"><ThumbsUp className="mr-3 text-blue-500"/> <span className="text-lg">Help others achieve homeownership</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="contact" className="py-16 bg-gray-100">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-8 text-center">Contact Us</h2>
                        <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl mx-auto">
                            <p className="text-xl mb-6 text-center">Ready to get started? Contact Peter Soares Jr. today for a free consultation!</p>
                            <ul className="space-y-4">
                                <li className="flex items-center justify-center"><Phone className="mr-3 text-blue-600"/> <span className="text-lg">808-754-6326</span></li>
                                <li className="flex items-center justify-center"><Mail className="mr-3 text-blue-600"/> <span className="text-lg">petersoaresjr@gmail.com</span></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>

            <footer className="bg-gray-800 text-white p-6">
                <div className="container mx-auto text-center">
                    <p>&copy; 2024 Real Estate Dream to Own. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default MainContent;