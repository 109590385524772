// src/components/tables/ViewSellers.js
import React, { useEffect, useState } from 'react';
import { db } from 'services/firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import LoadingScreen from 'components/common/LoadingScreen';
import { FaUser, FaPhone, FaEnvelope, FaMapMarkerAlt, FaHome, FaBed, FaBath, FaListUl, FaDollarSign } from 'react-icons/fa';
import {FiTarget} from "react-icons/fi";

const ViewSellers = () => {
    const [loading, setLoading] = useState(true);
    const [sellers, setSellers] = useState([]);
    const [editingSeller, setEditingSeller] = useState(null);
    const [editFormData, setEditFormData] = useState({});
    const [hasMortgage, setHasMortgage] = useState(false);

    useEffect(() => {
        const fetchSellers = async () => {
            setLoading(true);
            try {
                const querySnapshot = await getDocs(collection(db, 'users'));
                const sellersList = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.role === 'customer' && data.interest === 'selling') {
                        sellersList.push({ id: doc.id, ...data });
                    }
                });
                setSellers(sellersList);
            } catch (error) {
                console.error('Error fetching sellers:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSellers();
    }, []);

    const handleEditClick = (seller) => {
        setEditingSeller(seller);
        setEditFormData({ ...seller });
        setHasMortgage(seller.mortgageAmount ? true : false);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleMortgageChange = (e) => {
        const hasNewMortgage = e.target.value === 'Yes';
        setHasMortgage(hasNewMortgage);
        if (!hasNewMortgage) {
            setEditFormData(prev => ({ ...prev, mortgageAmount: '' }));
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            const sellerRef = doc(db, 'users', editingSeller.id);
            const updatedData = {
                ...editFormData,
                mortgageAmount: hasMortgage ? editFormData.mortgageAmount : '',
            };
            await updateDoc(sellerRef, updatedData);
            setEditingSeller(null);
            setSellers((prev) => prev.map((seller) =>
                seller.id === editingSeller.id ? updatedData : seller
            ));
        } catch (error) {
            console.error('Error updating seller:', error);
        }
    };


    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div>
            <h2 className="text-2xl font-bold mb-6">Sellers</h2>
            <table className="min-w-full bg-white">
                <thead>
                <tr>
                    <th className="py-2 px-4 border-b"><FaUser /></th>
                    <th className="py-2 px-4 border-b"><FaPhone /></th>
                    <th className="py-2 px-4 border-b"><FaEnvelope /></th>
                    <th className="py-2 px-4 border-b"><FaListUl /></th>
                    <th className="py-2 px-4 border-b"><FaListUl /></th>
                    <th className="py-2 px-4 border-b">Ask Price</th>
                    <th className="py-2 px-4 border-b"><FaMapMarkerAlt /></th>
                    <th className="py-2 px-4 border-b">FMV</th>
                    <th className="py-2 px-4 border-b"><FiTarget />  $/Mo.</th>
                    <th className="py-2 px-4 border-b"><FaHome /></th>
                    <th className="py-2 px-4 border-b"><FaBed /></th>
                    <th className="py-2 px-4 border-b"><FaBath /></th>
                    <th className="py-2 px-4 border-b">Yr. Built️</th>
                    <th className="py-2 px-4 border-b"><FaDollarSign /></th>
                    <th className="py-2 px-4 border-b">⚙️</th>
                </tr>
                </thead>
                <tbody>
                {sellers.map((seller) => (
                    <tr key={seller.id}>
                        <td className="py-2 px-4 border-b">{seller.name}</td>
                        <td className="py-2 px-4 border-b">{seller.phone}</td>
                        <td className="py-2 px-4 border-b">{seller.email}</td>
                        <td className="py-2 px-4 border-b">{seller.contactMethod}</td>
                        <td className="py-2 px-4 border-b">{seller.contactTime}</td>
                        <td className="py-2 px-4 border-b">{seller.askingPrice || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{seller.propertyLocation || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{seller.currentMarketValue || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{seller.monthlyBudget || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{seller.targetedHousingTypes || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{seller.numBedrooms || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{seller.numBathrooms || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">{seller.yearBuilt || 'N/A'}</td>
                        <td className="py-2 px-4 border-b">
                            {seller.mortgageAmount ? `$${seller.mortgageAmount}` : 'No Mortgage'}
                        </td>
                        <td className="py-2 px-4 border-b">
                            <button className="text-blue-600 hover:underline"
                                    onClick={() => handleEditClick(seller)}>Edit
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {editingSeller && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-start justify-center overflow-y-auto pt-16">
                    <div className="bg-white p-8 rounded-md shadow-lg w-full max-w-lg mx-4 my-8">
                        <h2 className="text-2xl font-bold mb-4">Edit Seller - {editingSeller.name}</h2>
                        <form onSubmit={handleEditSubmit}>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="name">Name:</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={editFormData.name || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="phone">Phone:</label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    value={editFormData.phone || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={editFormData.email || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="contactMethod">Contact Method:</label>
                                <input
                                    type="text"
                                    id="contactMethod"
                                    name="contactMethod"
                                    value={editFormData.contactMethod || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="contactTime">Contact Time:</label>
                                <input
                                    type="text"
                                    id="contactTime"
                                    name="contactTime"
                                    value={editFormData.contactTime || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="mortgageStatus">Mortgage Status:</label>
                                <input
                                    type="text"
                                    id="mortgageStatus"
                                    name="mortgageStatus"
                                    value={editFormData.mortgageStatus || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="propertyLocation">Property Location:</label>
                                <input
                                    type="text"
                                    id="propertyLocation"
                                    name="propertyLocation"
                                    value={editFormData.propertyLocation || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="currentMarketValue">Current Market Value:</label>
                                <input
                                    type="number"
                                    id="currentMarketValue"
                                    name="currentMarketValue"
                                    value={editFormData.currentMarketValue || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="monthlyBudget">Targeted Monthly Lease:</label>
                                <input
                                    type="number"
                                    id="monthlyBudget"
                                    name="monthlyBudget"
                                    value={editFormData.monthlyBudget || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="targetedHousingTypes">Targeted Housing Types:</label>
                                <select
                                    id="targetedHousingTypes"
                                    name="targetedHousingTypes"
                                    value={editFormData.targetedHousingTypes || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                >
                                    <option value="">Select a Housing Type</option>
                                    <option value="Single Family Home">Single Family Home</option>
                                    <option value="Multi-Family Home">Multi-Family Home</option>
                                    <option value="Condominium">Condominium</option>
                                    <option value="Tiny Home">Tiny Home</option>
                                    <option value="Land">Land</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="numBedrooms">Number of Bedrooms:</label>
                                <input
                                    type="number"
                                    id="numBedrooms"
                                    name="numBedrooms"
                                    value={editFormData.numBedrooms || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="numBathrooms">Number of Bathrooms:</label>
                                <input
                                    type="number"
                                    id="numBathrooms"
                                    name="numBathrooms"
                                    value={editFormData.numBathrooms || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2" htmlFor="yearBuilt">Year Built:</label>
                                <input
                                    type="number"
                                    id="yearBuilt"
                                    name="yearBuilt"
                                    value={editFormData.yearBuilt || ''}
                                    onChange={handleEditChange}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Is the property
                                    mortgaged?</label>
                                <select
                                    name="hasMortgage"
                                    value={hasMortgage ? 'Yes' : 'No'}
                                    onChange={handleMortgageChange}
                                    className="w-full border rounded px-2 py-1"
                                >
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                            {hasMortgage && (
                                <div className="mb-4">
                                    <label className="block text-gray-700 font-semibold mb-2" htmlFor="mortgageAmount">Current Mortgage Amount:</label>
                                    <input
                                        type="number"
                                        id="mortgageAmount"
                                        name="mortgageAmount"
                                        value={editFormData.mortgageAmount || ''}
                                        onChange={handleEditChange}
                                        className="w-full border rounded px-2 py-1"
                                    />
                                </div>
                            )}
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    className="mr-4 px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
                                    onClick={() => setEditingSeller(null)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewSellers;