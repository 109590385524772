import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from 'contexts/AuthContext';
import LandingPage from 'pages/LandingPage';
import MainContent from 'components/layout/MainContent';
import WelcomePage from 'pages/WelcomePage';
import NewUserSignUp from 'components/auth/NewUserSignUp';
import SignIn from 'components/auth/SignIn';
import ProfileCompletion from 'components/auth/ProfileCompletion';
import CustomersPage from 'components/layout/CustomersPage';
import ProtectedRoute from 'utils/ProtectedRoute';

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/main" element={<MainContent />} />
                    <Route
                        path="/welcome"
                        element={
                            <ProtectedRoute requireProfileComplete={true}>
                                <WelcomePage />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/signup" element={<NewUserSignUp />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route
                        path="/profile-completion"
                        element={
                            <ProtectedRoute requireProfileComplete={false}>
                                <ProfileCompletion />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/customers"
                        element={
                            <ProtectedRoute allowedRoles={['business_owner']}>
                                <CustomersPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="*" element={<Navigate to="/main" replace />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
